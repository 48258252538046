import React, {useContext} from "react";

import HabitItem from "../../presentational/HabitItem/HabitItem";

import { HabitContext } from "../../../contexts/HabitContext";

import { Header, List, Card, Button, Divider, Image } from "semantic-ui-react";

import primalSupplements from '../HabitForm/ProductsList'

const getPrimalSuppplementByName = (name) => primalSupplements.find(el => el.text === name)

function CheckInHabitList() {
    let habitContext = useContext(HabitContext);
    let items = habitContext.habits;

    let hasInProgress = false;
    let itemsElements = items.map((item, index) => {
        if (item.longestStreak < 30) {
            const itemDets = getPrimalSuppplementByName(item.info.name);
            hasInProgress = true;
            // set time to 0 so that the only comparison is date
            let todaysDate = new Date().setHours(0, 0, 0, 0);
            let lastCheckedInDate = new Date(item.lastCheckedIn).setHours(0, 0, 0, 0);
            let buttonText = 'CHECK IN';
            if (todaysDate === lastCheckedInDate) {
                buttonText = 'ALREADY CHECKED IN TODAY'
            }
            return (
                <List.Item key={index}>
                    <Card>
                    <Image src={itemDets ? itemDets.image.src : null} />
                        <HabitItem values={item} />
                        <Button size="big" disabled={todaysDate === lastCheckedInDate} color={todaysDate !== lastCheckedInDate ? "yellow" : "grey"} onClick={() => {habitContext.checkInHabit(index)}}>
                        <Button.Content>{buttonText}</Button.Content>
                        </Button>
                    </Card>
                </List.Item>
            );
        } else {
            return null;
        }
    }).reverse();

    if (hasInProgress) {
        return (
            <>
            <Divider  style={{marginTop:'50px'}}/>
                <Header as="h1" textAlign="center"  style={{color:'#528771', marginBottom:'25px'}}>In Progress Habits</Header>
                <List horizontal className='habitsl'>
                    {itemsElements}
                </List>
            </>
        );
    } else {
        return (
            <Header as="h1" textAlign="center">You haven't added any Primal Habits yet!</Header>
        );
    }
}

export default CheckInHabitList;
