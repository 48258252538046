import equal from 'deep-equal';

// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyATvJCe1qmn3IiRwUzo09b0qjXU_cnQyI4",

  authDomain: "primal-habits-b317a.firebaseapp.com",

  projectId: "primal-habits-b317a",

  storageBucket: "primal-habits-b317a.appspot.com",

  messagingSenderId: "435453870584",

  appId: "1:435453870584:web:62fc2d509c541d7846960d",

  measurementId: "G-WZKVWE64P4"

};

// Initialize Firebase

const firebaseApp = initializeApp(firebaseConfig);

const firestore = getFirestore();

let userDataRef;

async function getUserData(user) {
    const userDoc = doc(firestore, `users/${user}`);
    const res = await getDoc(userDoc);
    
    if (res.exists()) {
        const resData = res.data().dataAray;
        userDataRef =  JSON.parse(JSON.stringify(resData));
        return resData;
    }
    return []
}

function updateUserData(user, data) {
    const userDoc = doc(firestore, `users/${user}`);
    if (data.length === 0 || equal(userDataRef, data)) {
        return;
    }
    setDoc(userDoc, {dataAray: data})
    userDataRef =  JSON.parse(JSON.stringify(data));
}

// eslint-disable-next-line
const analytics = getAnalytics(firebaseApp);

export { updateUserData, getUserData }
