import React, {useContext} from "react";

import { HabitContext } from "../../../contexts/HabitContext";

import { Header, List, Card, Container, Divider } from "semantic-ui-react";

function CompletedHabitList() {
    let habitContext = useContext(HabitContext);
    let items = habitContext.habits;

    let hasCompleted = false;
    let itemsElements = items.map((item, index) => {
        if (item.longestStreak >= 66) {
            hasCompleted = true;
            return (
                <List.Item key={index}>
                    <Card fluid>
                        <Card.Content header={`Habit: ${item.info.name}`} />
                        <Card.Content>
                            <Card.Description>{item.info.description}</Card.Description>
                            <List>
                                <List.Item>
                                    <List.Header>Date of Completion</List.Header>
                                    {new Date(item.lastCheckedIn).toLocaleDateString()}
                                </List.Item>
                            </List>
                        </Card.Content>
                    </Card>
                </List.Item>
            );
        } else {
            return null;
        }
    }).reverse();

    if (hasCompleted) {
        return (
            <>
                        <Divider  style={{marginTop:'50px'}}/>
                <Header as="h1" style={{color:'#528771'}}>Completed Habits</Header>
                <List relaxed="very">
                    {itemsElements}
                </List>
            </>
        );
    } else {
        return (
            <Container text textAlign="center">
                            <Divider  style={{marginTop:'50px'}}/>

            <Header as="h1" style={{color:'#528771'}} textAlign="center">Completed Habits</Header>
            <p>
               You currently haven't completed any Primal Habits. Once you complete a 30-day habit, it will appear here and we will contact you with your reward!
            </p>
        </Container>
            );
    }
}

export default CompletedHabitList;
