import React, { useContext, useState } from "react";
import {Redirect} from "react-router-dom";

import { HabitContext } from "../../contexts/HabitContext";

import DeleteHabitList from "../../components/container/DeleteHabitList/DeleteHabitList";

import { Header, Button, Container,Image } from "semantic-ui-react";

function Delete() {
    let habitContext = useContext(HabitContext);
    let habits = habitContext.habits;

    let [redirect, setRedirect] = useState(false);

    if (redirect) {
        return (
            <Redirect to="/" />
        );
    } else {
        return (
            <>
             <Container as="header" text textAlign="center">
            <Image onClick={() => {setRedirect(true)}} style={{marginBottom:'25px'}} src='https://convertri.imgix.net/ec38b49e-9a26-11ea-abef-0697e5ca793e/3c7ba99ad1a60facece5a6c98def443858c59089/primal_habits.png' centered size='medium' />
            <Header style={{color:'#528771', marginBottom:'25px'}} as='h1' textAlign="center">Remove a Primal Habit</Header>
                    <p style={{marginBottom:'25px'}}>
Click "Delete" under the habits you would like to remove. All progress will be lost.                    </p>
<Button style={{marginBottom:'25px', borderRadius:'0px'}} onClick={() => {setRedirect(true)}}>
BACK TO HOME                </Button>
                </Container>

                <DeleteHabitList items={habits} />
                <Button size="big" style={{marginTop:'50px', width:'100%', borderRadius:'0px'}} onClick={() => {setRedirect(true)}}>
BACK TO HOME                </Button>
            </>
        );
    }
}

export default Delete;
