import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import { Form, Button, Dropdown } from 'semantic-ui-react'
import primalSupplements from './ProductsList'

function HabitForm (props) {
  let [name, setName] = useState('')
  let [disableSubmit, setDisableSubmit] = useState(false)
  let [redirect, setRedirect] = useState(false)

  let onSubmit = e => {
    e.preventDefault()
    setDisableSubmit(true)
    props.onSubmit(name)
    setRedirect(true)
  }

  
  if (redirect) {
    return <Redirect to='/' />
  } else {
    return (
      <Form onSubmit={onSubmit}>
        <Form.Field>
          <Dropdown
            placeholder='Select Supplement'
            fluid
            selection
            style={{borderRadius:'0px'}}
            options={primalSupplements}
            onChange={(e, { value }) => {
              console.log(value)
              setName(value)
            }}
          />
        </Form.Field>
        <Button
          style={{ marginTop: '20px', width: '100%', borderRadius:'0px',backgroundColor: '#528771', color:'white'}}
          size='big'
          color={!name ? 'grey' : '#528771'}
          type='submit'
          disabled={!name || disableSubmit}
        >
          START HABIT
        </Button>
      </Form>
    )
  }
}
export default HabitForm
