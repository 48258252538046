    let primalSupplements = [
        {
          key: 'Primal Multivitamin',
          text: 'Primal Multivitamin',
          value: 'Primal Multivitamin',
          image: {
            avatar: true,
            src:
              'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/product_primal-multi-vitamin.webp?v=7498101182812160010'
          }
        },
        {
          key: 'Primal Greens',
          text: 'Primal Greens',
          value: 'Primal Greens',
          image: {
            avatar: true,
            src:
        'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/product_primal-greens.webp?v=3481156382202341252'
          }
        },
        {
          key: 'Primal Immune Defense',
          text: 'Primal Immune Defense',
          value: 'Primal Immune Defense',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/product_primal-immune-defense.webp?v=5005413299471298807'
          }
        },
        {
          key: 'Primal Collagen',
          text: 'Primal Collagen',
          value: 'Primal Collagen',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/midres_product_primal-collagen.webp?v=9485923924148793708'
          }
        },
        {
          key: 'Primal Turmeric Complex',
          text: 'Primal Turmeric Complex',
          value: 'Primal Turmeric Complex',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/midres_product_turmeric-complex-with-bioperine.webp?v=16541889921867803001'
          }
        },
        {
          key: 'Primal Multi Collagen',
          text: 'Primal Multi Collagen',
          value: 'Primal Multi Collagen',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/midres_product_primal-multi-collagen.webp?v=9567594343666433602'
          }
        },
        {
          key: 'Primal Keto Collagen',
          text: 'Primal Keto Collagen',
          value: 'Primal Keto Collagen',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/midres_product_keto-collagen.webp?v=6646290223632983166'
          }
        },
        {
          key: 'Primal Flex',
          text: 'Primal Flex',
          value: 'Primal Flex',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/midres_product_primal-flex.webp?v=17089514423474647402'
          }
        },
        {
          key: 'Primal Probiotics',
          text: 'Primal Probiotics',
          value: 'Primal Probiotics',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/midres_product_primal-probiotics.webp?v=10976369060463337965'
          }
        },
        {
          key: 'Primal Gut Restore',
          text: 'Primal Gut Restore',
          value: 'Primal Gut Restore',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/midres_product_primal-gut-restore.webp?v=11609910382864298114'
          }
        },
        {
          key: 'Primal Omega 3',
          text: 'Primal Omega 3',
          value: 'Primal Omega 3',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/midres_product_primal-omega-3.webp?v=8092426354529399550'
          }
        },
        {
          key: 'Primal Mind Fuel',
          text: 'Primal Mind Fuel',
          value: 'Primal Mind Fuel',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/midres_product_primal-mind-fuel.webp?v=7432995882546067128'
          }
        },
        {
          key: 'Primal Sleep',
          text: 'Primal Sleep',
          value: 'Primal Sleep',
          image: {
            avatar: true,
            src:
              'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/product_primal-sleep.webp?v=12275987190710029957'
          }
        },
        {
          key: 'Hair Growth Complex',
          text: 'Hair Growth Complex',
          value: 'Hair Growth Complex',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/midres_product_hair-growth-complex.webp?v=14720450134431962301'
          }
        },
        {
          key: 'Primal Total Cleanse',
          text: 'Primal Total Cleanse',
          value: 'Primal Total Cleanse',
          image: {
            avatar: true,
            src:
           'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/product_primal-total-cleanse.webp?v=16151348693907310314'
          }
        },
        {
          key: 'Apple Cider Vinegar Gummies',
          text: 'Apple Cider Vinegar Gummies',
          value: 'Apple Cider Vinegar Gummies',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/midres_product_apple-cider-vinegar-gummies.webp?v=8436670248246911047'
          }
        },
        {
          key: 'Turmeric Ginger Gummies',
          text: 'Turmeric Ginger Gummies',
          value: 'Turmeric Ginger Gummies',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/midres_product_turmeric-ginger-gummies.webp?v=17881205227197092289'
          }
        },
        {
          key: 'Mushroom Extract Gummies',
          text: 'Mushroom Extract Gummies',
          value: 'Mushroom Extract Gummies',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/midres_product_mushroom-extract-gummies.webp?v=8830914213352919451'
          }
        },
        {
          key: 'Elderberry Gummies with Zinc',
          text: 'Elderberry Gummies with Zinc',
          value: 'Elderberry Gummies with Zinc',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/midres_product_elderberry-gummies-with-zinc.webp?v=7885903615789617920'
          }
        },
        {
          key: 'Orange Vitamin C Gummies',
          text: 'Orange Vitamin C Gummies',
          value: 'Orange Vitamin C Gummies',
          image: {
            avatar: true,
            src:
            'https://cdn.shopify.com/s/files/1/0094/2925/4223/t/17/assets/midres_product_orange-vitamin-c-gummies.webp?v=5163465462868738144'
          }
        }
      ]

export default primalSupplements

