import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { HabitProvider } from "../../contexts/HabitContext";

import Home from "../../views/Home/Home";
import Create from "../../views/Create/Create";
import Delete from "../../views/Delete/Delete";

import styles from "./App.module.css";
import Login from "./Login";

function App() {
    const email = new URLSearchParams(document.location.search).get('email');
    console.log(email);
    if (!email) {
        return <Login />
    }
    return (
        <Router>
            <HabitProvider email={email}>
                    <main className={styles.main}>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/create" component={Create} />
                            <Route exact path="/delete" component={Delete} />
                        </Switch>
                    </main>
            </HabitProvider>
        </Router>
    );
}

export default App;