import React, {useContext, useState} from "react";
import {Redirect} from "react-router-dom";

import { HabitContext } from "../../contexts/HabitContext";

import HabitForm from "../../components/container/HabitForm/HabitForm";

import { Header, Button, Container, Image } from "semantic-ui-react";

function Create() {
    let habitContext = useContext(HabitContext);

    let [redirect, setRedirect] = useState(false);
    if (redirect) {
        return (
            <Redirect to="/" />
        );
    } else {
        return (
            <>
            <Container as="header" text textAlign="center">
            <Image onClick={() => {setRedirect(true)}} style={{marginBottom:'25px'}} src='https://convertri.imgix.net/ec38b49e-9a26-11ea-abef-0697e5ca793e/3c7ba99ad1a60facece5a6c98def443858c59089/primal_habits.png' centered size='medium' />
                    <Header style={{color:'#528771', marginBottom:'25px'}} as='h1' textAlign="center">Start a Primal Habit</Header>
                    <p style={{marginBottom:'25px'}}>
                       Select a new supplement from the list and click the button to start your next 30-day Primal Habit journey!
                    </p>
                </Container>
                <HabitForm onSubmit={habitContext.addHabit} />
                <br />
                <Button style={{marginTop:'25px', width:'100%', borderRadius:'0px'}} onClick={() => {setRedirect(true)}}>
BACK TO HOME                </Button>
            </>
        );
    }
}

export default Create;
