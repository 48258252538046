import React, {useState} from "react";
import { Redirect } from "react-router-dom";

import CheckInHabitList from "../../components/container/CheckInHabitList/CheckInHabitList";

import CompletedHabitList from "../../components/container/CompletedHabitList/CompletedHabitList";

import StreakCount from "../../components/container/StreakCount/StreakCount";

import { Container, Button, Divider, Image } from 'semantic-ui-react'

function Home() {
    let [destination, setDestination] = useState(null);

    if (destination) {
        return (
            <Redirect to={destination} />
        );
    } else {
        return (
            <>
                <Container as="header" text textAlign="center">
                <Image style={{marginBottom:'25px'}} src='https://convertri.imgix.net/ec38b49e-9a26-11ea-abef-0697e5ca793e/3c7ba99ad1a60facece5a6c98def443858c59089/primal_habits.png' centered size='medium' />
                    <p>
                        Did you know? On average, it takes about 30 days to get the most out of a dietary supplement.
Once you’ve hit a 30-day streak of checking in, you should be experiencing the benefits of your new supplement routine. And, as an extra perk, you can be proud of yourself for successfully forming a new habit!
                    </p><br/>
                    <p> 
                  ⭐️  ️Limited Time: Complete a 30-day streak and we will send you a free bottle of choice from our delicious Primal Gummies! ⭐️
</p>
                </Container>
                <Divider horizontal />
                <Container  as="nav" textAlign="center">
                    <Button className="homebtn" style={{backgroundColor: '#528771', color:'white'}}  onClick={() => {setDestination("/create")}}>
ADD A HABIT                    </Button>
                    <Button className="homebtn" onClick={() => {setDestination("/delete")}}>
REMOVE A HABIT                    </Button>
                </Container>
    
                <StreakCount />
                <CheckInHabitList />
                <CompletedHabitList />
            </>
        );
    }
}

export default Home;
